@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Yrsa:ital,wght@0,300..700;1,300..700&display=swap');

:root {
	--foreground-rgb: 0, 0, 0;
	--background-start-rgb: 0, 0, 0;
	--background-end-rgb: 0, 0, 0;
}

body {
	color: rgb(var(--foreground-rgb));
}

header .left-text {
    font-family: "Yrsa", serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    color:#7A4316;
}

h1 {
    color:#7A4316;
}

nav {
    color:#7A4316;
    font-weight: 400;
}

a {
    border-bottom:1px solid #fff;
    transition: all 0.5s ease;
}

a:hover {
    color: #222;
    border-color: #666;
}

.cover-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

@layer base {
    p {
       margin-bottom:1rem;
       text-align: justify;
    }
 }

 .grecaptcha-logo, .grecaptcha-badge {
    display: none;
 }